import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication.service';



@Injectable({
  providedIn: 'root'
})
export class ValidTokenGuard implements CanActivate {  

  constructor(private router: Router, private authenticationService: AuthenticationService, ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            
      const authenticated = !!this.authenticationService.verifyToken()
      
      if (!authenticated) {
        this.router.navigate(['/auth']);
      }
      return authenticated;
  }

}
