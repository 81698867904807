import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { ValidTokenGuard } from './shared/guards/valid-token.guard';
import { CustomService } from 'src/app/core/services/custom.service';

const isBamba = CustomService.prototype.isBamba();
const routes: Routes = [
  {
    path: 'company',
    loadChildren: () => import('./pages/company-page/company-page.module').then(m => m.CompanyPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'trips',
    loadChildren: () => import('./pages/trips-page/trips-page.module').then(m => m.TripsPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'inquiries',
    loadChildren: () => import('./pages/inquiries-page/inquiries-page.module').then(m => m.InquiriesPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/bookings-page/bookings-page.module').then(m => m.BookingsPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'matches',
    loadChildren: () => import('./pages/matches-page/matches-page.module').then(m => m.MatchesPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages-page/messages-page.module').then(m => m.MessagesPageModule),
    canActivate: [AuthGuard, ValidTokenGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth-page/auth-page.module').then(m => m.AuthPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password-page/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password-page/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: '',
    redirectTo: isBamba ? 'trips':'inquiries',
    pathMatch: 'full',

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
