import { A } from '@angular/cdk/keycodes';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UUID } from 'angular2-uuid';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bamba-guru';

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService
  ){

  }

  ngOnInit() {
   
    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     // console.log("sdfsdf");
        
    //     var a = UUID.UUID()
    //     var token = document.querySelector('script[src="https://desk.zoho.com/portal/api/web/inapp/664279000001118011?orgId=758851372"]')
    //     token.setAttribute('nonce',a); 
    //      var token1 = document.querySelector('meta[http-equiv="Content-Security-Policy"]')
        
    //     token1.setAttribute('content',`script-src * 'nonce-${a}' `); 
       
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };

    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });

    
    
  }  

}
