// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { CustomService } from 'src/app/core/services/custom.service';
const custom = new CustomService();

export const environment = {
  production: false,
  // baseUrl: `http://localhost:8000/api/v1/`,
  // B2C_URL: 'http://localhost:3000',
   baseUrl: custom.isBamba() ? 'https://devapi.bambatravel.com/api/v1/' : 'https://devapi.baboo.travel/api/v1/',
  // baseUrl: 'https://stagingapi.baboo.travel/api/v1/',

  // B2C_URL: 'https://dev.baboo.travel',
  B2C_URL: 'https://dev.baboo.travel',
  B2C_BAMBA_URL: 'https://bambaworld.com',

  GOOGLE_MAPS_PLACE_API_KEY: 'AIzaSyBgI5y9byC_n5fCBwIW8CGz0N9XZBar-PE',
  TALK_JS_APP_ID: 'tZW1mUEF',
  CLOUDINARY_CLOUD_NAME: 'dbcwcdjx6',
  CLOUDINARY_UPLOAD_PRESET: 'a0mk4cho',

  BAMBA_API: 'https://bambaworld.com/api/v1/',
  API_LAYER_KEY: 'OdDR4MbrPSqreuhyrpNQtaECUfIjatfN',
  API_LAYER_URL: 'https://api.apilayer.com/exchangerates_data/latest'


};

/*
//  * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
