import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import to from 'await-to-js';
import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = AuthenticationService.getToken();
    const compositeToken = `Bearer ${token}`;
    if (token && !request.url.includes(environment.API_LAYER_URL)) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'es',
          Authorization: compositeToken
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'es'
        }
      });
    }


    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => event, async (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              await this.refreshTokenOrLogout();
            }
          }
        })
      );
  }

  async refreshTokenOrLogout(): Promise<void> {
    const [error, response] = await to(this.authenticationService.refreshToken(AuthenticationService.getRefreshToken()).toPromise());
    if (!response) {
      this.authenticationService.logout();
      await this.router.navigate(['']);
    }
  }
}
