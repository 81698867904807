import { Observable } from 'rxjs';
import { CompanyGuruEndpoint } from './../../../endpoints/company-guru.endpoint';
import { Guru } from './../../../interfaces/company.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/core/services/abstract-http.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuruService extends AbstractHttpService<any> {
  endpoint = CompanyGuruEndpoint.rest;

  constructor(http: HttpClient) {
    super(http);
  }

  acceptAgreements(guruData): Observable<ArrayBuffer> {
    return this.http.post<any>(CompanyGuruEndpoint.acceptAgreements, guruData);
  }


}
