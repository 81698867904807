import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AbstractHttpService<T> {
  endpoint;
  companyEndpoint
  constructor(protected http: HttpClient) {
  }

  get(id): Observable<T> {
    return this.http.get<T>(`${this.endpoint}${id}/`);
  }

  list(): Observable<T[]> {
    return this.http.get<any>(this.endpoint).pipe(map(data => data.results));
  }
  getListByCompany(companyId: number): Observable<T[]> {
    return this.http.get<any>(this.companyEndpoint(companyId)).pipe(map(data => data.results));
  }

  update(id: number, data: Partial<T>): Observable<T> {
    // console.log(data)
    return this.http.patch<T>(`${this.endpoint}${id}/`, data);
  }


  add(data: T): Observable<T> {
    return this.http.post<T>(this.endpoint, data);
  }

  invite(data: T): Observable<T> {
    return this.http.post<T>(this.endpoint + 'invite/', data);
  }

  delete(id: number): Observable<T> {
    return this.http.delete<T>(`${this.endpoint}${id}/`);
  }

}
