import {environment} from '../../../environments/environment';

export class AuthenticationEndpoint {
  static authToken = `${environment.baseUrl}token/`;
  static forgot_link = `${environment.baseUrl}forgot-password/`;
  static reset_link = `${environment.baseUrl}reset-password/`;
  static refreshToken = `${environment.baseUrl}token/refresh/`;
  static verifyToken = `${environment.baseUrl}token/verify/`;
  static socialToken = `${environment.baseUrl}social-token/`;
  static me = `${environment.baseUrl}token/me/`;
}
