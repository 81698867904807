import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AUTH_ME_STORAGE, AUTH_REFRESH_TOKEN, AUTH_TOKEN_STORAGE } from '../constants/const';
import { Auth, AuthCredentials, AuthToken } from '../../shared/interfaces/auth.interface';
import { AuthenticationEndpoint } from '../../shared/endpoints/authentication.endpoint';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  protected authenticationSubscription = new BehaviorSubject(AuthenticationService.getAuthData());

  constructor(private http: HttpClient, private router: Router) {
  }

  static getToken(): string {
    return localStorage.getItem(AUTH_TOKEN_STORAGE);
  }

  static getRefreshToken(): string {
    return localStorage.getItem(AUTH_REFRESH_TOKEN);
  }

  static setToken(access: string, refresh?: string): void {
    localStorage.setItem(AUTH_TOKEN_STORAGE, access);
    if (refresh) {
      localStorage.setItem(AUTH_REFRESH_TOKEN, refresh);
    }
  }

  static getAuthData(): Auth {
    return JSON.parse(localStorage.getItem(AUTH_ME_STORAGE));
  }

  static setAuthData(data: Auth): void {
    localStorage.setItem(AUTH_ME_STORAGE, JSON.stringify(data));
  }

  static setRefreshToken(refresh: string): void {
    localStorage.setItem(AUTH_REFRESH_TOKEN, refresh);
  }

  authenticate(credentials: AuthCredentials): Observable<Auth> {
    return this.http.post<AuthToken>(AuthenticationEndpoint.authToken, credentials)
      .pipe(
        tap((data: { refresh: string, access: string }) => AuthenticationService.setToken(data.access, data.refresh)),
        switchMap(data => this.me())
      );
  }
  forgot_this(credentials) {
    // return this.http.get<any>(this.companyEndpoint(companyId)).pipe(map(data => data.results));
    return this.http.post(AuthenticationEndpoint.forgot_link, credentials)
   
  }

reset_this(credentials) {
  // return this.http.get<any>(this.companyEndpoint(companyId)).pipe(map(data => data.results));
  return this.http.post(AuthenticationEndpoint.reset_link, credentials)
 
}


  refreshToken(refresh: string): Observable<{ access: string }> {
    return this.http.post<{ access: string }>(AuthenticationEndpoint.refreshToken, { refresh })
      .pipe(
        tap(token => AuthenticationService.setToken(token.access))
      );
  }

  verifyToken(): Observable<any> {
    return this.http.post(AuthenticationEndpoint.verifyToken, { token: AuthenticationService.getToken() })
      .pipe(
        catchError(err => {
          return this.refreshToken(AuthenticationService.getRefreshToken())
            .pipe(
              catchError(err1 => {
                this.logout();
                this.router.navigate(['/auth']);
                return EMPTY;
              })
            );
        })
      );
  }

  getAuthenticationSubscription(): Observable<boolean> {
    return this.authenticationSubscription.asObservable()
      .pipe(switchMap(() => {
        return of(!!AuthenticationService.getAuthData());
      }));
  }

  me(): Observable<Auth> {
    return this.http.get<Auth>(AuthenticationEndpoint.me)
      .pipe(
        tap(user => {
          AuthenticationService.setAuthData(user);
          this.authenticationSubscription.next(user);
        }));
  }

  logout(): void {
    localStorage.clear();
    this.authenticationSubscription.next(null);
  }
}
