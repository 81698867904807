import '@angular/compiler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './core/interceptors/http.request.interceptor';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AgmCoreModule } from '@agm/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { environment } from '../environments/environment';

import {
  CloudinaryModule,
  CloudinaryConfiguration
} from "@cloudinary/angular-5.x";
import { Cloudinary } from "cloudinary-core";


@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    GooglePlaceModule,
    HttpClientModule,

    MatSnackBarModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-WXJZ77G'
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDCthYDDwa4azw48BAAk4dBQBhEdLocKRI'
    }),
    CloudinaryModule.forRoot(Cloudinary, {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME
    } as CloudinaryConfiguration)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
