import { ExpertiseMetadata } from '../../shared/interfaces/common';

export const AUTH_TOKEN_STORAGE = '__TOKEN_STORAGE';
export const AUTH_ME_STORAGE = '__ME';
export const AUTH_REFRESH_TOKEN = '___REFRESH___';

export const EXPERTISE_DESCRIPTIONS: { [key: string]: ExpertiseMetadata } = {
  traveller_types: {
    title: 'Traveler Types',
    description: 'Select which type(s) of travelers yours trips are made for.',
    column_per_row: 3,
    has_review: false
  },
  average_durations: {
    title: 'Average Duration',
    description: 'Specify the general average duration for the trips you operate.',
    column_per_row: 3,
    has_review: false
  },
  travel_styles: {
    title: 'Travel Styles',
    description: 'Select the categories that best describe the travel style of your trips.',
    column_per_row: 4,
    has_review: false,
  },
  activities: {
    title: 'Activities',
    description: 'Select the activities generally included in your trips.',
    column_per_row: 4,
    has_review: false,
    review_metadata: {
      title: 'Are you an expert in trips with a different Activities?',
      description: 'Please write a different style here. We will review the submission and use it to select the most suitable travelers for your trips.',
    }
  },
  languages: {
    title: 'Language(s)',
    description: 'Select only the language(s) in which you are able to operate your trips.',
    column_per_row: 3
  },
  accommodations: {
    title: 'Accommodations',
    description: 'Select the accommodation type(s) generally included in your trips.',
    column_per_row: 3,
    has_review: false
  }
};


export const MESSAGES = {
  WELCOME: 'Congratulations, you have been approved as a bamba guru!  \n' +
    'Please help us fill out the rest of your profile.  Once this is done we will contact you to go through the on-boarding process.  Exciting!'
};


export const REVIEW = {
  headers: ['rating', 'traveller', 'travel_date', 'review_date', 'review'],
  reviews: [
    {
      rating: 3.5,
      traveller: 'Alexis L Z',
      travel_date: '15/01/2020',
      review_date: '15/01/2020',
      review: 'The tour in and of itself was excellent. Non-stop breath taking views for four days, a quite challenging hike and the Grand …'
    },
    {
      rating: 5,
      traveller: 'Laurent S',
      travel_date: '15/01/2020',
      review_date: '15/01/2020',
      review: 'The tour in and Laurent S itself was excellent. Non-stop breath taking views for four days, a quite challenging hike and the Grand …'
    },
    {
      rating: 3.5,
      traveller: 'Alexis L Z',
      travel_date: '15/01/2020',
      review_date: '15/01/2020',
      review: 'It’s an excellent way to experience a small portion of Japan in such a short time frame, I absolutely adored my tour …'
    },
  ]
};

export const MEDIA = {
  general: [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
    },
    {
      id: 2,
      image: 'https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
    },
    {
      id: 3,
      image: 'https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
    }
  ]
};

export const PRICING = {
  isTraveller: true,
  currencys: [{ currency: 'USD', id: 1 }, { currency: 'MXN', id: 2 }],
  categories: [
    {
      id: 'category_1',
      main: true,
      name: 'Category1 (Hostel Dorm)',
      traveller: {
        paxes: [{ id: 'c1_1', value: '0' }]
      },
      group: {
        group_paxes: [
          {
            id: 'gp1_1',
            min: 2,
            max: 8,
            net_price: 0
          }
        ]
      }
    },
    {
      id: 'category_2',
      main: false,
      name: 'Category2 (Hostel Private)',
      traveller: {
        paxes: [{ id: 'c2_1', value: '0' }]
      },
      group: {
        group_paxes: [
          {
            id: 'gp2_1',
            min: 2,
            max: 8,
            net_price: 0
          }
        ]
      }
    }
  ]
};

export const ITINERARY = {
  EXCLUSIONS: {
    exclusions: [
      {
        exclusion: 'International Flights',
        id: 'exc_0'
      },
      {
        exclusion: 'International Flights',
        id: 'exc_1'
      }
    ]
  },
  DAYBDAY: {
    trip_name: 'Lake Titicaca Adventure',
    url_preview: 'http://preview.bambatravel.com/123456',
    days: [
      {
        id: 'd1',
        day: 1,
        date: '01/01/2020',
        title: 'Andean Road Adventure from Cuzco to Puno',
        description: 'Take the bus on the amazing road going from Cuzco to Puno and make stops along the way to discover some of the most beautiful Peruvian wonders.'
      },
      {
        id: 'd2',
        day: 2,
        date: '01/02/2020',
        title: 'Depart for the Uros Floating Islands, Taquile Island & Luquina Homestay',
        description: 'You will be picked up from your accommodation and transferred to the port of Puno to board a motorboat to the charming Uros Floating Islands and Taquile Island. Later, continue on to Luquina community to meet your host family and enjoy a delicious dinner.'
      },
      {
        id: 'd3',
        day: 3,
        date: '01/03/2020',
        title: 'Luquina community & Return to Puno',
        description: ''
      }
    ],
  }
};
