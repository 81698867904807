import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
const custom = new CustomService();

if (environment.production) {
  if(!custom.isBamba()){
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WXJZ77G');`;
    document.head.appendChild(gtmScript);


    const gtmSrc = document.createElement('link');
      gtmSrc.innerHTML = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WXJZ77G"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
      document.body.prepend(gtmSrc.firstChild);
  }


  enableProdMode();
}

if(custom.isBamba()){
  window.parent.document.title = "Bamba Travel";
  
  const favicon = document.createElement('link');
  favicon.innerHTML = `<link rel="icon" type="image/png" sizes="16x16" href="assets/bamba-favicon.ico">`;
  document.head.appendChild(favicon.firstChild);

} else {
  window.parent.document.title = "Baboo Travel";

  const favicon = document.createElement('link');
  favicon.innerHTML = `<link rel="icon" type="image/png" sizes="16x16" href="assets/favicon-16x16.png">`;
  document.head.appendChild(favicon.firstChild);
  favicon.innerHTML = `<link rel="icon" type="image/png" sizes="32x32" href="assets/favicon-32x32.png">`;
  document.head.appendChild(favicon.firstChild);
  favicon.innerHTML = `<link rel="apple-touch-icon" sizes="180x180" href="assets/apple-touch-icon.png">`;
  document.head.appendChild(favicon.firstChild);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
